import React from 'react';
import './FooterContatos.css';
import { FaWhatsapp } from 'react-icons/fa';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";



export default function FooterContatos(){
    return (<div className="backgimage">
            <div className="fileira">

            
                <div className="coluna">
                <strong className="strong2">Endereço</strong>
                    <ul className="list-unstyled">
                        <li>Avenida Bandeirantes, 3131</li>
                        <li>Campo Grande, MS</li>
                        <li>CEP 79006-001</li>
                    </ul>

                </div>
            
                <div className="coluna">
                    <strong className="strong2">Redes Sociais</strong>
                    <ul className="list-unstyled">
                        <a href="https://api.whatsapp.com/send?phone=5567998383492&text=Ol%C3%A1%20estou%20visitando%20seu%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!" target="_blank" rel="noreferrer" ><FaWhatsapp className="FaWhatsappCSS"/></a>
                        <a href="https://www.facebook.com/coautomacao" target="_blank" rel="noreferrer" ><FaFacebook className="TiSocialInstagramCSS"/></a>
                        <a href="https://www.instagram.com/coautomacao/" target="_blank" rel="noreferrer" ><FaInstagram className="TiSocialInstagramCSS"/></a>
                        <a href="https://www.linkedin.com/company/coautomacao/" target="_blank" rel="noreferrer" ><FaLinkedin className="TiSocialInstagramCSS"/></a>
                    </ul>

                </div>

                <div className="coluna">
                <strong className="strong2">Contatos</strong>
                    <ul className="list-unstyled">
                        <li>Email: comercial@coautomacao.com</li>
                        <li>Telefone: 67 3022 6200</li>
                    </ul>

                </div>
            </div>
            <div className="fileira2">
                Copyright© 2023 COAUTOMAÇÃO, Todos os Direitos Reservados.
            </div>
        </div>
    )
}