import React, { Component } from 'react';
import NavBar from '../Components/NavBar/NavBar.js';
import './Catalogo.css';
import Footer from '../Components/Footer/Footer.js';
import FooterContatos from '../Components/Footer/FooterContatos.js';
import ButtonC from '../Components/ButtonCatalogo/ButtonC';
import Distribuicao from '../Images/Catalogo/SENSEWEG.jpg';
import WEGP from '../Images/Catalogo/WEG.jpg';
import FloatingWhatsApp from 'react-floating-whatsapp';
import LOGOL from '../Images/logo2.png';



class Conversores extends Component {
 render() {
 return (
   <div className="backGBlack">
   <NavBar/>
   <div className="CatalogoText1"><strong>Produtos</strong></div>
    <div className="EspacoBotoes"></div>
  <div className="ButtonsItens">
  <ButtonC/>
     </div>
     <div className="EspacoBotoes"></div>
   <div className="CatalogoPageCss">
     <img src={Distribuicao} width="auto" alt="WEG"></img>
  
     <img src={WEGP} width="auto" alt="PRODUTOSWEG"></img>
     
   </div>
   <div className="EspacoBotoes"></div>
   <Footer/>
   <FooterContatos/>
   <FloatingWhatsApp avatar={LOGOL} position="absolute" accountName="Centro Oeste" allowClickAway="true" placeholder="Escreva sua mensagem"  chatMessage="Olá, como posso te ajudar?" statusMessage="online" allowEsc="true" phoneNumber="5567998383492"/>
</div>
 );
 }
}
export default Conversores;