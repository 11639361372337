import React, { Component } from 'react';
import NavBar from '../Components/NavBar/NavBar.js';
import FooterContatos from '../Components/Footer/FooterContatos.js';
import Footer from '../Components/Footer/Footer.js';
import './Contato.css';
import FloatingWhatsApp from 'react-floating-whatsapp';
import LOGOL from '../Images/logo2.png';

class Contato extends Component {
 render() {
 return (
   <div className="backGBlack">
   <NavBar/>
   <div className="ContatoText1"><strong>Contato</strong></div>
   <div className="ContatoPageCss">
   <div className="rowContato">
  <div className="columnContato"><h3><h6>Email:</h6> comercial@coautomacao.com</h3><ul/><h3><h6>Telefone:</h6> (67) 3022-6200</h3><ul/><h3><h6>Endereço:</h6> Avenida Bandeirantes, 3131 Campo Grande, MS  CEP 79006-001</h3></div>
  <div className="column2Contato"><iframe className="mapaCss" title="googlemaps" id="gmapcanvas" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Avenida Bandeirantes, 3131 Campo Grande, MS CEP 79006-001&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div></div>
   </div>
   <Footer/>
   <FooterContatos/>
   <FloatingWhatsApp avatar={LOGOL} position="absolute" accountName="Centro Oeste" allowClickAway="true" placeholder="Escreva sua mensagem"  chatMessage="Olá, como posso te ajudar?" statusMessage="online" allowEsc="true" phoneNumber="5567998383492"/>
</div>
 );
 }
}
export default Contato;