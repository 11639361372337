import React from 'react';
import './ClientesButton.css';
import imagelogo from '../../Images/parceiros.jpeg';


export default function Button6(){
    return (
        <div>
            <button className="ClientesButton"href="Catalago">

                <img className="img-fluid" src={imagelogo} alt="imagesites"/>
    
            </button>
        </div>
    )
}