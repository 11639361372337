import React from 'react';
import './CatalogoButton.css';
import { Link } from 'react-router-dom';


export default function Button3(){
    return (
        <div>
            <Link to="/catalogo"><button className="CatalogoButton">Produtos</button></Link> 
        </div>
    )
}