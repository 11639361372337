import './App.css';
import CoaPage from './Components/CoaPage.js';

function App() {
  return (
    <CoaPage/>
  );
}

export default App;
