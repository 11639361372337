import React from 'react';
import './servicosText.css';
import { Link } from 'react-router-dom';


export default function Button5(){
    return (
        <div>
            <Link to="/servicos" target="_top" >
            <button className="servicosText">
            » Assistência Técnica Autorizada Weg Drives
            <ul/>
            » Manutenção Corretiva em Drives Multimarcas
            <ul/>
            » Manutenção Preventiva a Campo
            <ul/>
            » Startup e Acompanhamento
            <ul/>
            » Treinamento e Aprimoramento Técnico
            <ul/>
            </button></Link> 
        </div>
    )
}