import React, { Component } from 'react';
import NavBar from '../Components/NavBar/NavBar.js';
import './Catalogo.css';
import Footer from '../Components/Footer/Footer.js';
import FooterContatos from '../Components/Footer/FooterContatos.js';
import ButtonC from '../Components/ButtonCatalogo/ButtonC';
import SENSEWEG from '../Images/Catalogo/SENSEWEG.jpg';
import WEG from '../Images/Catalogo/WEG.jpg';
import SENSE from '../Images/Catalogo/SENSE.jpg';
import CONAULT from '../Images/Catalogo/CONAUT.jpg';
import FloatingWhatsApp from 'react-floating-whatsapp';
import LOGOL from '../Images/logo2.png';
import CONAUT2 from '../Images/Catalogo/conault.png' ;


class Catalogo extends Component {
 render() {
 return (
   <div className="backGBlack">
   <NavBar/>
   <div className="CatalogoText1"><strong>Produtos</strong></div>
    <div className="EspacoBotoes"></div>
  <div className="ButtonsItens">
  <ButtonC/>
     </div>
     <div className="EspacoBotoes"></div>
   <div className="CatalogoPageCss">
     <img src={SENSEWEG} width="auto" alt="SENSEWEG"></img>
     
     <img src={WEG} width="auto" alt="WEG"></img>
     
     <img src={SENSE} width="auto" alt="SENSE"></img>

     <img src={CONAUT2} width="auto" alt="CONAUT1"></img>
     
     <img src={CONAULT} width="auto" alt="CONAULT"></img>
     
    
     
   </div>
   <div className="espaco"></div>
   <Footer/>
   <FooterContatos/>
   <FloatingWhatsApp avatar={LOGOL} position="absolute" accountName="Centro Oeste" allowClickAway="true" placeholder="Escreva sua mensagem"  chatMessage="Olá, como posso te ajudar?" statusMessage="online" allowEsc="true" phoneNumber="5567998383492"/>
</div>
 );
 }
}
export default Catalogo;