import React from 'react';
import './ContatoButton.css';
import { Link } from 'react-router-dom';

export default function Button4(){
    return (
        <div>
            <Link to="/contato"><button className="ContatoButton">Contato</button></Link> 
        </div>
    )
}