import React, { Component } from 'react';
import NavBar from '../Components/NavBar/NavBar.js';
import './Servicos.css';
import FooterContatos from '../Components/Footer/FooterContatos.js';
import Footer from '../Components/Footer/Footer.js';
import GERAL from "../Images/Catalogo/Geral.jpg";
import GERAL2 from "../Images/Catalogo/SENSEWEG.jpg";
import logoeduca from "../Images/logo-branca.png";
import ButtonE1 from "../Components/ButtonsEmpresa/coaText.js";
import ButtonE2 from "../Components/ButtonsEmpresa/coatextbutton.js";
import FloatingWhatsApp from 'react-floating-whatsapp';
import LOGOL from '../Images/logo2.png';

class Empresa extends Component {
 render() {
 return (
   <div className="bgcolor1">
   <NavBar/>
   <div className="ServicosText1"><strong>Empresa</strong></div>
   <div className="espacob2"/>
   <div className="apresentacao"><h5 className="h52"><h1 className="i2">Centro Oeste Automação Industrial</h1><ul/>A Centro Oeste Automação Industrial é uma empresa composta por várias soluções.</h5>
</div>
  <div className="row">
  <div className="columnEMPRESA"><h5><h6>Assistência Técnica</h6><ul/>Trabalhamos com o conserto e manutenção preventiva em inversor de frequência e soft starter.</h5></div>
  <div className="column2EMPRESA"><img className="imgr" src={GERAL} alt="reparo"/></div></div>
  
  <div className="row">
  <div className="columnEMPRESA"><h5><h6>Distribuição</h6><ul/>Somos distribuidores da linha baixa tensão WEG e linha de sensores e instrumentos Sense.</h5></div>
  <div className="column2EMPRESA"><img className="imgr" src={GERAL2} alt="reparo"/></div></div>  

  <div className="row">
  <div className="columnEMPRESA"><h5><h6>Treinamento</h6><ul/>Na parte de treinamento trabalhamos em parceria com a EducaDrives e o Eng. Alan</h5></div>
  <div className="column2EMPRESA"><img className="imgr" src={logoeduca} alt="reparo"/></div></div>
  <div className="espacob3"/>
  <div className="row3">
  <div className="e2column"><h5><i>Missão</i><ul/>Contribuir com o desenvolvimento de processos e inovações, proporcionando qualidade, respeito e ética em busca do melhor atendimento aos nossos clientes. <ul/></h5></div>
  </div>
  <div className="row3">
  <div className="e2column"><h5><i>Visão</i><ul/>Buscar o constante desenvolvimento de nossos colaboradores, clientes e fornecedores para desempenhar um excelente trabalho, promovendo o crescimento profissional e da empresa.</h5></div>
  </div>
  <div className="row3">
  <div className="e2column"><h5><i>Valores</i><ul/>ÉTICA, COMPROMETIMENTO, INOVAÇÃO, CONFIANÇA, QUALIDADE E RESPEITO!</h5></div>
  </div>
  <div className="espaco"></div>
  <ButtonE1/>
  <div className="espaco"></div>
  <ButtonE2/>
  <div className="espaco"></div>
  <Footer/>
   <FooterContatos/>
   <FloatingWhatsApp avatar={LOGOL} position="absolute" accountName="Centro Oeste" allowClickAway="true" placeholder="Escreva sua mensagem"  chatMessage="Olá, como posso te ajudar?" statusMessage="online" allowEsc="true" phoneNumber="5567998383492"/>
</div>
 );
 }
}
export default Empresa;