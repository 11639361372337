import React from 'react';
import './Footer.css';
import { GoPackage } from 'react-icons/go';
import { Ri24HoursFill } from 'react-icons/ri';
import { BiSupport } from 'react-icons/bi';


export default function Footer(){
    return (<div className="linha">
    <div className="coluna-25"><GoPackage className="icone3"/><ul/>PRONTA ENTREGA</div>
    <div className="coluna-25"><a className='a4' href="https://api.whatsapp.com/send?phone=5567998383492&text=Ol%C3%A1%20estou%20visitando%20seu%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!" target="_blank" rel="noreferrer"><Ri24HoursFill className="icone3"/><ul/>ATENDIMENTO EMERGENCIAL</a></div>
    <div className="coluna-25"><BiSupport className="icone3"/><ul/>SUPORTE TÉCNICO</div>
  </div>)
}