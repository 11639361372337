import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Contato from './Paginas/Contato.js';
import Servicos from './Paginas/Servicos.js';
import Empresa from './Paginas/Empresa.js';
import Catalogo from './Paginas/Catalogo.js';
import SoftStarter from './Paginas/SoftStarter.js';
import Motorredutores from './Paginas/Motorredutores.js';
import Conversores from './Paginas/Conversores.js';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

ReactDOM.render(
  
    <BrowserRouter>
        <Switch>
            <Route path="/" exact component={App} />
            <Route path="/servicos" component={Servicos} />
            <Route path="/empresa" component={Empresa} />
            <Route path="/catalogo" component={Catalogo} />
            <Route path="/SENSE" component={SoftStarter} />
            <Route path="/CONAUT" component={Motorredutores} />
            <Route path="/WEG" component={Conversores} />
            <Route path="/contato" component={Contato} />
        </Switch>
    </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
