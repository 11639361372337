import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../Images/coalogo.png';
import "./NavBar.css";
import Button1 from '../Buttons/HomeButton';
import Button2 from '../Buttons/ServicosButton';
import Button3 from '../Buttons/CatalogoButton';
import Button4 from '../Buttons/ContatoButton';
import { Link } from 'react-router-dom';

export default function NavBar(){
    return (<div className="backgroundNav">
        <Navbar className="NavBar-NavBarCSS"  bg="navbar navbar-light bg-lights justify-content-between">
        <Link to="/"><img src={logo} alt="logo"/></Link>
        <div className="NavBar-ButtonContent navbar mr-sm-2" ><Button1/><Button2/><Button3/><Button4/></div>
        </Navbar></div>
    )
}