import React from 'react';
import './ServicosButton.css';
import { Link } from 'react-router-dom';

export default function Button2(){
    return (
        <div>
            <Link to="/servicos"><button className="ServicosButton">Serviços</button></Link> 
        </div>
    )
}