import React from 'react';
import './ButtonC.css';
import { Link } from 'react-router-dom';


export default function ButtonC(){
    return (
        <div className="ButtonsItens2">
            <Link to="/WEG"><button className="ButtonC">WEG</button></Link> 
            <Link to="/SENSE"><button className="ButtonC">SENSE</button></Link> 
            <Link to="/CONAUT"><button className="ButtonC">CONAUT</button></Link>
        </div>
    )
}