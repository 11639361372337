import React, { Component } from 'react';
import Slider from "react-slick";
import logoCONAULT from "../../Images/logoCO.png";
import logoWEG from "../../Images/logoWEG.png";
import logoSENSE from "../../Images/logoSENSE.png";
import { Link } from 'react-router-dom';

export default class MultipleItems extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1
    };
    return (
      <div className="container">
				<link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
				<style>{cssstyle}</style>
        <Slider {...settings}>

        <Link to="/WEG">
          <div>
          <img className="h9" alt="logoWEG" src={logoWEG}/>
          </div>
        </Link>

          <Link to="/SENSE">
          <div>
          <img className="h9" alt="logoSENSE" src={logoSENSE}/>
          </div>
          </Link>

          <Link to="/CONAUT">
          <div>
          <img className="h9" alt="logoCONAUT" src={logoCONAULT}/>
          </div>
          </Link>
        
        </Slider>
      </div>
    );
  }
}

const cssstyle = `
.container {
  margin: 0 auto;
  padding: 0px 40px 40px 40px;
  width: auto;
  height: auto;
  justify-content: space-between;
  align-items: center;
}
.h9 {
    padding-left: 10px;
    padding-right: 10px;
    background: white;
    color: #fff;
    line-height: 100px;
    
}
.slick-track{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slick-next:before, .slick-prev:before {
    color: #f9e768;
}
`