import React from 'react';
import './HomeButtons.css';
import { Link } from 'react-router-dom'



export default function Button1(){
    return (
        <div>
            <Link to="/empresa"><button className="HomeButton">Empresa</button></Link>
        
        </div>
    )
}