import React from 'react';
import './coaText.css';
import logoedu from "../../Images/logoedu.png";
import logoCOA from "../../Images/coa3.png";
import { Link } from 'react-router-dom';



export default function ButtonE1(){
    return (
        <div className="TextCenter">
            <img className="imgpad" src={logoedu} alt="marcafluxo"/>
             <Link className="TextDecoration" to="/" target="_top"><img className="imgpad2" src={logoCOA} alt="marcafluxo"/></Link>
        
        </div>
    )
}