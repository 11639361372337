import React from 'react';
import NavBar from './NavBar/NavBar.js';
import FooterContatos from './Footer/FooterContatos.js';
import Footer from './Footer/Footer.js';
import Button5 from './Buttons/servicosText';
import Button6 from './Buttons/ClientesButton.js';
import Geral from '../Images/Geral.jpg';
import GERAL2 from '../Images/GERAL2.jpg';
import WEGCOA from '../Images/WEGCOA.jpeg';
import conaultl from '../Images/conaultlogo.png';
import LOGOL from '../Images/logo2.png';
import MultipleItems from './Slider/slider.js';
import Carousel from 'react-bootstrap/Carousel';
import './CoaPage.css';
import FloatingWhatsApp from 'react-floating-whatsapp';


export default class CoaPage extends React.Component {

  render() {
    return (
          <div >
          <NavBar />
  <div className="CarouselHome">
  <Carousel>
  <Carousel.Item interval={2000}>
    <img
      className="img-responsive"
      src={Geral}
      alt="First slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="img-responsive"
      src={GERAL2}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="img-responsive"
      src={conaultl}
      alt="third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="img-responsive"
      src={WEGCOA}
      alt="third slide"
    />
  </Carousel.Item>
</Carousel>
</div>
    <div className="espaco3"><strong>Produtos</strong></div>
    <div className="espaco"></div>
    <MultipleItems/>         
    <div className="espaco"></div>
        <div className="espaco3"><strong>Serviços e Parceiros</strong></div>
        <div className="espaco"></div>
        <div className="rowparceiros">
        <div className="columnparceiros">
        <Button5/>
        </div>
        <div className="columnparceiros2">
        <Button6/>
        </div>
        </div>
        <div className="espaco"></div>
        <Footer/>
        <FooterContatos/>
        <FloatingWhatsApp avatar={LOGOL} position="absolute" accountName="Centro Oeste" allowClickAway="true" placeholder="Escreva sua mensagem"  chatMessage="Olá, como posso te ajudar?" statusMessage="online" allowEsc="true" phoneNumber="5567998383492"/>
      </div>
      
    )
  }
}