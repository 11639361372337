import React, { Component } from 'react';
import NavBar from '../Components/NavBar/NavBar.js';
import './Catalogo.css';
import FooterContatos from '../Components/Footer/FooterContatos.js';
import Footer from '../Components/Footer/Footer.js';
import CATALOGO1 from '../Images/servicoss/CATALOGO1.jpg';
import CATALOGO2 from "../Images/servicoss/CATALOGO2.jpg";
import CATALOGO3 from "../Images/servicoss/CATALOGO3.jpg";
import CATALOGO4 from "../Images/servicoss/CATALOGO4.jpg";
import CATALOGO5 from "../Images/servicoss/CATALOGO5.jpg";
import FloatingWhatsApp from 'react-floating-whatsapp';
import LOGOL from '../Images/logo2.png';

class Servicos extends Component {
 render() {
 return (
   <div className="backGBlack">
   <NavBar/>
   <div className="ServicosText1"><strong>Serviços</strong></div>
   <div className="CatalogoPageCss">
   <img  src={CATALOGO1} alt="catalogo1"></img>
   <img  src={CATALOGO2} alt="catalogo2"></img>
   <img  src={CATALOGO3} alt="catalogo3"></img>
   <img  src={CATALOGO4} alt="catalogo4"></img>
   <img  src={CATALOGO5} alt="catalogo5"></img>
   </div>
   <Footer/>
   <FooterContatos/>
   <FloatingWhatsApp avatar={LOGOL} position="absolute" accountName="Centro Oeste" allowClickAway="true" placeholder="Escreva sua mensagem"  chatMessage="Olá, como posso te ajudar?" statusMessage="online" allowEsc="true" phoneNumber="5567998383492"/>
</div>
 );
 }
}
export default Servicos;