import React from 'react';
import './coaText.css';
import { Link } from 'react-router-dom';



export default function ButtonE2(){
    return (
        <div className="TextCenter">
             <Link className="TextDecoration" to="/Contato"><button className="coaText" href="Catalago">
                Contato: (67) 3022-6200 <ul/> Avenida Bandeirantes, 3131 | Campo Grande - MS | CEP 79006-001
             </button></Link>
        </div>
    )
}